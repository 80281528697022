import "./Skills.css";
import LineHeader from "../../components/LineHeader";
import Bar from "./Bar";

const Skills = () => {
 
 
  return (
    <section className="section__padding skills" id="skills">
      <h2>Skills</h2>
      <LineHeader />
      <div className="main-skill ">
        <div className="technical scroll-scale">
          <h3>Technical Skills</h3>
          <Bar title="HTML" percentage="85%" type="html" />
          <Bar title="Figma" percentage="70%" type="figma" />
          <Bar title="CSS" percentage="85%" type="css" />
          <Bar title="JS" percentage="80%" type="javascript" />
          <Bar title="React" percentage="80%" type="react" />
          <Bar title="NextJs" percentage="80%" type="nextJs" />
          <Bar title="NodeJs" percentage="80%" type="nodeJs" />
          <Bar title="Python" percentage="80%" type="python" />
          <Bar title="Java" percentage="70%" type="java" />
          <Bar title="SQL-PL/SQL" percentage="75%" type="sql" />
          <Bar title="NOSQL" percentage="75%" type="noSql" />
        </div>
        <div className="scroll-scale">
          <h3>professional Skills</h3>
          <div className="professional">
            <div className="box">
              <div
                className="circle-percentage"
                data-dots="80"
                data-percent="70"
              ></div>

              <div className="text-container">
                <big>70%</big>
                <small>Team Work</small>
              </div>
            </div>
            <div className="box">
              <div
                className="circle-percentage"
                data-dots="80"
                data-percent="75"
              ></div>
              <div className="text-container">
                <big>75%</big>
                <small>Project Management</small>
              </div>
            </div>
            <div className="box">
              <div
                className="circle-percentage"
                data-dots="80"
                data-percent="60"
              ></div>
              <div className="text-container">
                <big>60%</big>
                <small>Creativity</small>
              </div>
            </div>

            <div className="box">
              <div
                className="circle-percentage"
                data-dots="80"
                data-percent="80"
              ></div>
              <div className="text-container">
                <big>80%</big>
                <small>Communication</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
