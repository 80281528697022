import {useState} from 'react'
import "./Navbar.css"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";


const Navbar = () => {
     const [menuIcon, setMenuIcon] = useState(false);

     const handleClick = () =>{
      setMenuIcon(!menuIcon);
     }

  return (
    <section className="navbar section__padding">
      <div className="logo ">
        <span>R</span>
        imon
      </div>
      <ul className="navlist">
        <li>
          <a href="#home" style={{ "--i": 1 }} onClick={handleClick}>
            Home
          </a>
        </li>
        <li>
          <a href="#about" style={{ "--i": 2 }} onClick={handleClick}>
            About
          </a>
        </li>
        <li>
          <a href="#education" style={{ "--i": 3 }} onClick={handleClick}>
            Education
          </a>
        </li>
        <li>
          <a href="#skills" style={{ "--i": 4 }} onClick={handleClick}>
            Skills
          </a>
        </li>
        <li>
          <a href="#projects" style={{ "--i": 5 }} onClick={handleClick}>
            Projects
          </a>
        </li>
        <li>
          <a href="#contact" style={{ "--i": 6 }} onClick={handleClick}>
            Contact
          </a>
        </li>
      </ul>
      <div className="iconsBox" onClick={handleClick}>
        {menuIcon ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
      </div>

      {/* {menuIcon && (
        <div className="mobileIcon">
          <ul className="navlistMobile">
            <li>
              <a href="#home" onClick={handleClick}>
                Home
              </a>
            </li>
            <li>
              <a href="#about" onClick={handleClick}>
                About
              </a>
            </li>
            <li>
              <a href="#education" onClick={handleClick}>
                Education
              </a>
            </li>
            <li>
              <a href="#skills" onClick={handleClick}>
                Skills
              </a>
            </li>
            <li>
              <a href="#projects" onClick={handleClick}>
                Projects
              </a>
            </li>
            <li>
              <a href="#contact" onClick={handleClick}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      )} */}
    </section>
  );
}

export default Navbar