// https://lokesh-coder.github.io/blobs.app/?e=6&gw=6&se=7326&c=d1d8e0&o=0

import React from 'react'

const Imageblob = () => {
  return (
    <div className="header-image">
      <div className="image-box">
        <img src="/images/rimon.png" alt="profile" />
      </div>
      <div className="liquid-shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 500 500"
          width="100%"
          id="blobSvg"
        >
          <path id="blob" fill="#ffb43a">
            <animate
              attributeName="d"
              dur="20000ms"
              repeatCount="indefinite"
              values="M431,307.5Q450,365,408.5,409Q367,453,308.5,462Q250,471,207,435Q164,399,110.5,380.5Q57,362,38.5,306Q20,250,69.5,212.5Q119,175,128,114.5Q137,54,193.5,34Q250,14,308.5,30.5Q367,47,408,91Q449,135,430.5,192.5Q412,250,431,307.5Z;
                
                M400,290.5Q391,331,361,361Q331,391,290.5,444Q250,497,210,442.5Q170,388,118.5,372Q67,356,38,303Q9,250,30.5,193Q52,136,104.5,112Q157,88,203.5,57Q250,26,291.5,66Q333,106,377.5,128Q422,150,415.5,200Q409,250,400,290.5Z;
                
                M411.5,299.5Q422,349,376,368.5Q330,388,290,429.5Q250,471,193.5,458Q137,445,112,394.5Q87,344,93,297Q99,250,70.5,190Q42,130,93,98Q144,66,197,34Q250,2,305,30.5Q360,59,390,105.5Q420,152,410.5,201Q401,250,411.5,299.5Z;
                
                M442,294Q403,338,382.5,390.5Q362,443,306,452.5Q250,462,209.5,426Q169,390,126.5,368Q84,346,80.5,298Q77,250,82,203Q87,156,130,136Q173,116,211.5,83Q250,50,302.5,59Q355,68,371,119.5Q387,171,434,210.5Q481,250,442,294Z;
                
                M455,309.5Q457,369,397.5,385.5Q338,402,294,417Q250,432,203.5,421.5Q157,411,115.5,381Q74,351,40,300.5Q6,250,43.5,201Q81,152,115.5,115Q150,78,200,42Q250,6,309,25.5Q368,45,400.5,95Q433,145,443,197.5Q453,250,455,309.5Z;

                M453,306.5Q445,363,392,383Q339,403,294.5,413.5Q250,424,195.5,431.5Q141,439,105.5,396.5Q70,354,75.5,302Q81,250,99,211.5Q117,173,137.5,132Q158,91,204,74.5Q250,58,306.5,55.5Q363,53,387.5,104.5Q412,156,436.5,203Q461,250,453,306.5Z;

                M444,304.5Q439,359,406.5,411.5Q374,464,312,469Q250,474,209,433.5Q168,393,142.5,360Q117,327,103,288.5Q89,250,104,212Q119,174,138,131Q157,88,203.5,80Q250,72,302,70.5Q354,69,384.5,112Q415,155,432,202.5Q449,250,444,304.5Z;
                
                M431,307.5Q450,365,408.5,409Q367,453,308.5,462Q250,471,207,435Q164,399,110.5,380.5Q57,362,38.5,306Q20,250,69.5,212.5Q119,175,128,114.5Q137,54,193.5,34Q250,14,308.5,30.5Q367,47,408,91Q449,135,430.5,192.5Q412,250,431,307.5Z"
            ></animate>
          </path>
        </svg>
      </div>
      <div className="liquid-shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 500 500"
          width="100%"
          id="blobSvg"
        >
          <path id="blob" fill="#ffb43a">
            <animate
              attributeName="d"
              dur="20000ms"
              repeatCount="indefinite"
              values="M431,307.5Q450,365,408.5,409Q367,453,308.5,462Q250,471,207,435Q164,399,110.5,380.5Q57,362,38.5,306Q20,250,69.5,212.5Q119,175,128,114.5Q137,54,193.5,34Q250,14,308.5,30.5Q367,47,408,91Q449,135,430.5,192.5Q412,250,431,307.5Z;
                
                M400,290.5Q391,331,361,361Q331,391,290.5,444Q250,497,210,442.5Q170,388,118.5,372Q67,356,38,303Q9,250,30.5,193Q52,136,104.5,112Q157,88,203.5,57Q250,26,291.5,66Q333,106,377.5,128Q422,150,415.5,200Q409,250,400,290.5Z;
                
                M411.5,299.5Q422,349,376,368.5Q330,388,290,429.5Q250,471,193.5,458Q137,445,112,394.5Q87,344,93,297Q99,250,70.5,190Q42,130,93,98Q144,66,197,34Q250,2,305,30.5Q360,59,390,105.5Q420,152,410.5,201Q401,250,411.5,299.5Z;
                
                M442,294Q403,338,382.5,390.5Q362,443,306,452.5Q250,462,209.5,426Q169,390,126.5,368Q84,346,80.5,298Q77,250,82,203Q87,156,130,136Q173,116,211.5,83Q250,50,302.5,59Q355,68,371,119.5Q387,171,434,210.5Q481,250,442,294Z;
                
                M455,309.5Q457,369,397.5,385.5Q338,402,294,417Q250,432,203.5,421.5Q157,411,115.5,381Q74,351,40,300.5Q6,250,43.5,201Q81,152,115.5,115Q150,78,200,42Q250,6,309,25.5Q368,45,400.5,95Q433,145,443,197.5Q453,250,455,309.5Z;

                M453,306.5Q445,363,392,383Q339,403,294.5,413.5Q250,424,195.5,431.5Q141,439,105.5,396.5Q70,354,75.5,302Q81,250,99,211.5Q117,173,137.5,132Q158,91,204,74.5Q250,58,306.5,55.5Q363,53,387.5,104.5Q412,156,436.5,203Q461,250,453,306.5Z;

                M444,304.5Q439,359,406.5,411.5Q374,464,312,469Q250,474,209,433.5Q168,393,142.5,360Q117,327,103,288.5Q89,250,104,212Q119,174,138,131Q157,88,203.5,80Q250,72,302,70.5Q354,69,384.5,112Q415,155,432,202.5Q449,250,444,304.5Z;
                
                M431,307.5Q450,365,408.5,409Q367,453,308.5,462Q250,471,207,435Q164,399,110.5,380.5Q57,362,38.5,306Q20,250,69.5,212.5Q119,175,128,114.5Q137,54,193.5,34Q250,14,308.5,30.5Q367,47,408,91Q449,135,430.5,192.5Q412,250,431,307.5Z"
            ></animate>
          </path>
        </svg>
      </div>
    </div>
  );
}

export default Imageblob