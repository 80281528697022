import React from 'react'
import "./Education.css"
import LineHeader from '../../components/LineHeader';

const Education = () => {
  return (
    <section className="section__padding education scroll-scale" id="education">
      <h2>Education</h2>
      <LineHeader />
      <div className="education-main">
        <div className="circle-line">
          <div className="circle"></div>
          <div className="line"></div>
          <div className="circle"></div>
          <div className="line"></div>
        </div>
        <div className="education-container">
          <div className="education1">
            <h4>Diploma of Software development</h4>
            <h5>2022-2024</h5>
            <p>
              I am a recent graduate of the Southern Alberta Institute of
              Technology (SAIT) in Canada, where I completed a diploma in
              Software Development. I have gained an in-depth understanding of
              designing and implementing efficient database structures. I have
              acquired valuable skills in managing and maintaining databases.
              This includes tasks such as backup and recovery, security
              implementation, and performance optimization. I have developed a
              strong understanding of ensuring data integrity, security, and
              efficient retrieval.
            </p>
            <p>
              A significant focus of my studies has been on web development. I
              have gained practical experience in both frontend and backend
              development. On the frontend side, I have been exposed to HTML,
              CSS, and JavaScript, as well as frameworks like React, enabling me
              to create dynamic and responsive user interfaces. On the backend
              side, I have honed my skills in using Node.js, Express.js, and
              working with databases, allowing me to develop robust server-side
              logic and data management for web applications.
            </p>
            <p>
              The object-oriented programming coursework has empowered me to
              design and implement software solutions using the principles of
              modularity and code reusability. I have become proficient in
              programming languages such as Java, and honed my skills in
              encapsulation, inheritance, and polymorphism. This foundation has
              provided me with the ability to create well-structured and
              scalable software applications.
            </p>
            <p>
              During my academic studies, I maintained a solid GPA of 3.94,
              demonstrating my commitment to academic excellence and dedication
              to my coursework.
            </p>
          </div>
          <div className="education2">
            <h4>Bachelor of Computer science</h4>
            <h5 className="date">2002 - 2005</h5>
            <p>
              I have had a Bachelor's Degree in Computer Science from
              Al_Rafidain University in Iraq, obtained between 2002 and 2005. I
              completed a diverse range of coursework that provided me with a
              comprehensive understanding of various aspects of computer
              science. I gained valuable insights in subjects such as Operating
              Systems, where I studied the fundamental principles and components
              of modern operating systems, including process management, memory
              management, and file systems. This coursework equipped me with the
              knowledge to efficiently utilize and optimize computer resources.
            </p>
            <p>
              Additionally, I undertook coursework in Computer Security, which
              allowed me to explore techniques and methodologies for protecting
              computer systems and networks from unauthorized access and
              malicious attacks.
            </p>
            <p>
              These courses, along with others like Data Structures and
              Algorithms, and Database Management, have provided me with a
              well-rounded education in computer science. I am confident in
              applying the knowledge and skills gained from these coursework to
              contribute effectively in a professional setting. During my
              academic studies, I maintained a solid GPA of 3.3, demonstrating
              my commitment to academic excellence and dedication to my
              coursework.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Education