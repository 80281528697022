import React from 'react'
import "./Projects.css"
import LineHeader from "../../components/LineHeader";
import ProjectList from './ProjectList';

const Projects = () => {
 
  return (
    <section className="section__padding projects" id="projects">
      <h2>Latest Projects</h2>
      <LineHeader />
      <div className="container">
        <div className="projects-gallery scroll-bottom">
          <ProjectList
            src="/images/restaurant.png"
            alt="project5"
            title="Restaurant site"
            href="https://restaurant-chi-swart.vercel.app/"
          />
          <ProjectList
            src="/images/bussiness.png"
            alt="project6"
            title="Business site"
            href="https://gpt-black-seven.vercel.app/"
          />
          <ProjectList
            src="/images/plumbing.png"
            alt="project4"
            title="Plumbing site"
            href="https://www.mountainsmechanical.com/en"
          />
          <ProjectList
            src="/images/measure-app.png"
            alt="project1"
            title="Weight, Temperature, and Distance measurement "
            href="https://measurement-app.vercel.app/index.html"
          />

          <ProjectList
            src="/images/taco.png"
            alt="project2"
            title="Taco shop"
            href="https://lab5-taco-shop.vercel.app/"
          />

          <ProjectList
            src="/images/Quiz.png"
            alt="project3"
            title="Quiz App"
            href="https://quiz-app-alpha-rouge.vercel.app/"
          />
        </div>
      </div>
    </section>
  );
}

export default Projects