import { useEffect } from "react";
import "./App.css";
import { About, Contact, Education, Skills, Projects } from "./containers";

import { Navbar, Header, Footer } from "./components";

function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    const loadScript = () => {
      document.body.appendChild(script);
    };
    if (!document.querySelector('script[src="./main.js"]')) {
      script.addEventListener("load", loadScript);
      document.body.appendChild(script);
    }
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <Navbar />
      <Header/>
      <About />
      <Education />
      <Skills />
      <Projects />
      <Contact />
      <Footer/>
    </div>
  );
}

export default App;
