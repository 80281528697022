import React from 'react'
const Bar = ({title, percentage, type}) => {
  return (
    <div className="skill-bar">
      <div className="info">
        <p>{title}</p>
        <p>{percentage}</p>
      </div>
      <div className="bar">
        <span className={type}></span>
      </div>
    </div>
  );
}

export default Bar