import React from "react";
import "./About.css";
import LineHeader from "../../components/LineHeader";

const About = () => {
  return (
    <section className="section__padding scroll-scale" id="about" >
      <h2>About Me</h2>
      <LineHeader />
      <div className="about">
        <div className="about-image">
          <img src="/images/rimon.png" alt="profile" />
          <div className="info-about1">
            <span>8+</span>
            <p>Years of Experience</p>
          </div>
          <div className="info-about2">
            <span>25+</span>
            <p>Project Complete</p>
          </div>
        </div>

        <div className="desc">
          <p>
            Highly skilled web developer with more than 9 years of experience . I am passionate about delivering exceptional and innovative web solutions that not only meet client
            objectives but also exceed their expectations.
          </p>

          <p>
            I am a recent graduate of the Southern Alberta Institute of
            Technology (SAIT) in Canada, where I completed a diploma in Software
            Development. Additionally, I hold a Bachelor's degree in Computer
            Science from Iraq.
          </p>

          <p>
            Proficient in front-end and back-end development, I excel in HTML,
            CSS, and JavaScript, and have experience working with
            frameworks such as Node.js and React JS. I am highly adaptable and
            continuously stay updated with the latest industry trends and
            technologies to ensure the delivery of cutting-edge web
            applications.
          </p>
          <p>
            I possess excellent project management skills, enabling me to
            effectively handle multiple projects simultaneously. I take pride in
            setting realistic deadlines and consistently delivering completed
            work on time, ensuring client satisfaction and maintaining a track
            record of successful project completions. Moreover, my strong
            communication and interpersonal skills enable me to establish
            positive client relationships and provide exceptional support
            throughout the development process.
          </p>
          <p>
            I am currently seeking new opportunities where I can leverage my
            extensive experience, diverse skill set, and passion for creating
            exceptional web solutions to contribute to the success of a dynamic
            organization. Connect with me to explore potential collaborations in
            web development or to discuss how my skills can benefit your
            organization.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
