import {useRef, useState} from 'react'
import axios from "axios"

const Form = () => {
  const formRef = useRef(null);
 const [error, setError] = useState();

 const handleChange = (e) => {
   setError("");
 };

 const handleSubmitClick = async (ev) => {
   ev.preventDefault();
   const formData = new FormData(ev.currentTarget);

   /*get data one by one */
   // const email = formData.get("email")
   // console.log(email)

   /* get data as array */
   // console.log([...formData.entries()])

   /* get data as object */
   const userInput = Object.fromEntries(formData);

if (!formData.get("name") || !formData.get("email") || !formData.get("subject") || !formData.get("message")) {
  setError("All fields are required");
  return;
}
try {
  const res = await axios.post("/api/forma", userInput);

  if (res.status === 200) {
    formRef.current.reset();
  }

  setError(res.data.message);

  setTimeout(() => {
    setError("");
  }, 10000);
  
} catch (error) {
  console.log(error)
  
}



 
/* reset form data*/
// ev.currentTarget.reset()


 };
  return (
    <div className="form-container scroll-bottom">
      {error && <span className="text">{error}</span>}
      <form ref={formRef} onSubmit={handleSubmitClick}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          onChange={handleChange}
          // value={clientInfo.name}
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          onChange={handleChange}
          // value={clientInfo.email}
        />
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          onChange={handleChange}
          // value={clientInfo.subject}
        />
        <textarea
          name="message"
          id=""
          cols="30"
          rows="10"
          placeholder="Your Message"
          onChange={handleChange}
          // value={clientInfo.message}
        />
        <div className="btn-box">
          <button type="submit" className="btn">
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
}

export default Form