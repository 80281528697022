// need to install npm i typewriter-effect
import Typewriter from "typewriter-effect";


const TextTransition = () => {
return (
  <h3>
    <span className="text">
      <Typewriter
        options={{
          strings: ["Frontend web developer", "Backend web developer", "Web designer", "Freelancer"],
          autoStart: true,
          loop: true,
          delay: 80,
        }}
      />
    </span>
  </h3>
);
 
};

export default TextTransition;


