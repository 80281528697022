import React from "react";
import "./Header.css";
import TextTransition from "../TextTransition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import Imageblob from "./Imageblob";




const Header = () => {
  return (
    <div className="header section__padding scroll-scale" id="home">
      <div className="header-info ">
        <h1>Hi! I'm Rimon Yousuf</h1>
        <div className="change-text">
          <h3>I am </h3>
          <TextTransition />
        </div>
        <div className="describe">
          <p>
            Full stack web developer with a proven track record of 9 years in
            the field of web development.
          </p>
        </div>
        <div className="email-info">
          <h5>Email: </h5>
          <span className="text">rimon.alqoshi@gmail.com</span>
        </div>
        <div className="btn-box">
          <a
            href="Rimon-Alqoshi-CV.pdf"
            download="Rimon-Alqoshi-Resume.pdf"
            className="btn"
          >
            Download CV
          </a>
        </div>
        <div className="social-icons">
          <a href="https://www.github.com/Rimon83" target="__blank">
            <FontAwesomeIcon icon={faGithub} size="xl" />
          </a>
          <a
            href="https://www.linkedin.com/in/rimon-alqoshi-it"
            target="__blank"
          >
            <FontAwesomeIcon icon={faLinkedin} size="xl" />
          </a>
          {/* <a href="https://www.facebook.com/rimon.alqoshi" target="__blank">
            <FontAwesomeIcon icon={faFacebook} size="xl" />
          </a> */}
        </div>
      </div>

      <Imageblob />
    </div>
  );
};

export default Header;
