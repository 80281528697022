import React from 'react'
import"./Footer.css"

const Footer = () => {
  return (
    <div className="section__padding footer">
      <p>Copyright &copy; Rimon Yousuf 2019-{new Date().getFullYear()}</p>
    </div>
  );
}

export default Footer