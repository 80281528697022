import React from 'react'
import "./Contact.css"
import LineHeader from "../../components/LineHeader"
import Form from './Form'

const Contact = () => {
  return (
    <section className="section__padding contact scroll-scale" id="contact">
      <h2>Contact Me</h2>
      <LineHeader />
      <Form />
    </section>
  );
}

export default Contact